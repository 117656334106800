





















import { Component, Vue, VModel, Prop } from 'vue-property-decorator';

@Component({})
export default class CCDropDown extends Vue {
  @VModel({ type: Boolean }) open!: boolean;
  @Prop() public label!: string; 
  public popupFilterBefore = false;
}
