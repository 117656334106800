






































































































import { Component, Prop, Vue, Watch, Mixins } from "vue-property-decorator";
import date from 'quasar/src/utils/date.js';;
import CCToggle from "@/components/CCToggle.vue";
import CCGraph from "@/components/CCGraph.vue";
import CCDropDown from "@/components/CCDropDown.vue";
import { SocketIOMixin } from "../io-utils";
import { POLLUTANTS_FILTER_OPTIONS, getPollutantsOptionsForArea } from "@/pollutants";
const { addToDate } = date;
import { i18n } from "../i18n";

@Component({
  components: {
    CCToggle,
    CCGraph,
    CCDropDown,
  },
})
export default class AirQualityAreaGraph extends Mixins(SocketIOMixin) {
  @Prop() public areaId!: number;
  @Prop() public areaLastData!: any;
  public $store: any;

  public dateRanges: any = [
    { value: "realtime", label: i18n.t("DR_REALTIME") },
    { value: "today", label: i18n.t("DR_TODAY") },
    { value: "yesterday", label: i18n.t("DR_YESTERDAY") },
    { value: "7days", label: i18n.t("DR_LAST_7DAYS") },
    { value: "30days", label: i18n.t("DR_LAST_30DAYS") },
    { value: "thisyear", label: i18n.t("DR_THIS_YEAR") },
    // {value: 'custom', label: 'Personnalisé'},
  ];
  public dateRangeValue: string = "realtime";
  public dateRangeCustomFrom: any = null;
  public dateRangeCustomTo: any = null;
  public filterOptions: any = POLLUTANTS_FILTER_OPTIONS;
  public selectedFilterOptions: any = POLLUTANTS_FILTER_OPTIONS;
  public loading: boolean = false;
  public popupFilter: boolean = false;
  public popupDateRange: boolean = false;
  public popupFilterBefore: boolean = false;
  public popupDateRangeBefore: boolean = false;
  private needRefresh: boolean = false;

  public created() {
    this.loading = true;

    const graphDateRange = localStorage.getItem("graphDateRange");
    const graphPollutants = localStorage.getItem("graphPollutants");

    if (graphDateRange !== undefined && graphDateRange !== null) {
      this.dateRangeValue = JSON.parse(graphDateRange);
    }
    if (graphPollutants !== undefined && graphPollutants !== null) {
      const graphPollutantsParsed: any = JSON.parse(graphPollutants) || [];
      this.selectedFilterOptions = this.filterOptions.filter(
        (entry: any) => graphPollutantsParsed.indexOf(entry.value) !== -1
      );
    }

    this.refresh();
  }

  public refresh() {
    const area = this.$store.getters.getAreaFromId(this.areaId);
    if (area) {
      this.filterOptions = getPollutantsOptionsForArea(area);
      this.selectedFilterOptions = this.filterOptions.filter((entry: any) =>
        this.selectedFilterOptionsValues.includes(entry.value)
      );
    }
  }

  private selectFilterOption(option: any) {
    if (this.selectedFilterOptionsValues.indexOf(option.value) === -1) {
      this.selectedFilterOptions = this.filterOptions.filter(
        (entry: any) => this.selectedFilterOptionsValues.indexOf(entry.value) !== -1 || option.value === entry.value
      );
    } else {
      if (this.selectedFilterOptions.length === 1) {
        return;
      }
      this.selectedFilterOptions = this.selectedFilterOptions.filter((entry: any) => entry.value !== option.value);
    }
  }

  get selectedFilterOptionsValues() {
    return this.selectedFilterOptions.map((entry: any) => entry.value);
  }

  @Watch("selectedFilterOptionsValues")
  private saveSelectedFilterOptionsValues() {
    localStorage.setItem("graphPollutants", JSON.stringify(this.selectedFilterOptionsValues));
  }

  @Watch("dateRangeValue")
  private saveDateRangeValue() {
    localStorage.setItem("graphDateRange", JSON.stringify(this.dateRangeValue));
  }

  @Watch("areaId")
  private onParamsChanged() {
    this.needRefresh = true;
    this.$nextTick().then(() => {
      if (this.needRefresh) {
        this.loading = true;
        this.refresh();
        this.needRefresh = false;
      }
    });
  }

  get dateRangeFrom() {
    const drv = this.dateRangeValue;
    // Get UTC date
    // see https://stackoverflow.com/questions/948532/how-do-you-convert-a-javascript-date-to-utc
    const localedate = new Date();
    const utcnow = new Date(
      Date.UTC(
        localedate.getUTCFullYear(),
        localedate.getUTCMonth(),
        localedate.getUTCDate(),
        localedate.getUTCHours(),
        localedate.getUTCMinutes(),
        localedate.getUTCSeconds()
      )
    );

    if (drv === "realtime") {
      return null;
    } else if (drv === "today") {
      return date.startOfDate(utcnow, "day");
    } else if (drv === "yesterday") {
      return date.startOfDate(addToDate(utcnow, { days: -1 }), "day");
    } else if (drv === "7days") {
      return date.startOfDate(addToDate(utcnow, { days: -7 }), "day");
    } else if (drv === "30days") {
      return date.startOfDate(addToDate(utcnow, { days: -30 }), "day");
    } else if (drv === "thisyear") {
      return date.startOfDate(addToDate(utcnow, { year: -1 }), "day");
    } else if (drv === "custom") {
      return this.dateRangeCustomFrom;
    }
  }

  get dateRangeText() {
    if (this.dateRangeValue === "custom") {
      //
    } else {
      return this.dateRanges.filter((entry: any) => {
        return entry.value === this.dateRangeValue;
      })[0].label;
    }
  }

  get dateRangeTo() {
    const drv = this.dateRangeValue;
    if (drv === "realtime") {
      return null;
    } else if (drv === "today") {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === "yesterday") {
      return addToDate(this.dateRangeFrom, { days: 1 });
    } else if (drv === "7days") {
      return addToDate(this.dateRangeFrom, { days: 7 });
    } else if (drv === "30days") {
      return addToDate(this.dateRangeFrom, { days: 30 });
    } else if (drv === "thisyear") {
      return addToDate(this.dateRangeFrom, { year: 1 });
    } else if (drv === "custom") {
      return this.dateRangeCustomTo;
    }
  }

  get dateRangeLabelFormat() {
    const drv = this.dateRangeValue;
    if (drv === "realtime") {
      return "HH:mm";
    } else if (drv === "today") {
      return "HH:mm";
    } else if (drv === "yesterday") {
      return "HH:mm";
    } else if (drv === "7days") {
      return "DD/MM HH";
    } else if (drv === "30days") {
      return "DD/MM";
    } else if (drv === "thisyear") {
      return "MM/YY";
    } else if (drv === "custom") {
      return "DD/MM HH:mm";
    }
  }
}
