










import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LineChart from '../components/LineChart.vue';
import date from 'quasar/src/utils/date.js';;

@Component({
  components: {
    LineChart,
  },
})
export default class CCGraph extends Vue {
  @Prop() public indicators!: string[];
  @Prop() public areaId!: number;
  @Prop() public analyzerId!: number;
  @Prop() public dtFrom!: null | string | number | Date;
  @Prop() public dtTo!: null | string | number | Date;
  @Prop() public labelFormat!: string;
  @Prop() public refreshKey!: number;

  public $store: any;
  private chartOptions: object = {};
  private chartData: {
    labels: string[],
    datasets: any,
  } = {
    labels: [],
    datasets: null,
  };
  private needRefresh: boolean = false;
  private data: any;

  public getData() {
    return this.data;
  }

  public refresh(): void {
    let loadFunc: string = 'loadAreaData';
    const params: any = {
      indicators: this.indicators,
      smooth: true,
    };

    if (this.areaId !== undefined) {
      params.areaId = this.areaId;
    } else {
      params.analyzerId = this.analyzerId;
      loadFunc = 'loadAnalyzerData';
    }

    if (this.dtFrom !== null && this.dtTo !== null) {
      params['dt_start'] = date.formatDate(
          this.dtFrom, 'YYYY-MM-DDTHH:mm:ss.SSS000') + 'Z';
      params['dt_end'] = date.formatDate(
          this.dtTo, 'YYYY-MM-DDTHH:mm:ss.SSS000') + 'Z';
    } else {
      params['realtime'] = true;
    }
    const canvas: any = document.getElementById('canvas')!;
    const ctx = canvas.getContext('2d');
    let gradientFill = ctx.createLinearGradient(0, 0, 0, 100);
    gradientFill.addColorStop(0, 'rgba(134, 199, 201, 0.5)');
    gradientFill.addColorStop(1, 'rgba(134, 199, 201, 0.1)');

    this.$store.dispatch(loadFunc, params).then((response: any) => {
      this.data = response;
      const chartOptions: any = {
        animation: false,
        scales: {
          yAxes: [{
            display: true,
            gridLines: {
              borderDash: [2, 2],
              color: '#87c7c844',
            },
            scaleLabel: {
              display: false,
              labelString: 'Value',
            },
            ticks: {
              fontSize: 10,
              fontColor: '#637d90',
              beginAtZero: true,
              precision: 1,
            },
          }],
          xAxes: [{
            gridLines: {
              color: '#87c7c844',
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 10,
              padding: 10,
              fontSize: 10,
              fontColor: '#637d90',
            },
          }],

        },
        annotation: {
          annotations: [],
        },
      };

      const colorCritical = '#F4A49F';
      const colorWarning = '#E5B398';
      const indicatorFull = this.indicators[0];
      const indicator = indicatorFull.split(':')[0];
      const indicatorUnit = indicatorFull.split(':').pop();
      if (indicatorFull === 'co2:ppm') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 1200;

      } else if (indicatorFull === 'cov:ppb') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 400;

      } else if (indicatorFull === 'cov:ugm3') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 1600;

      } else if (indicatorFull === 'no2:ppb') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 400;

      } else if (indicatorFull === 'no2:ugm3') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 800;

      } else if (indicatorFull === 'pm1:ugm3') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 30;

      } else if (indicatorFull === 'pm25:ugm3') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 45;

      } else if (indicatorFull === 'pm10:ugm3') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 50;

      } else if (indicatorFull === 'temperature:celsius') {
        let tempMax: number = Math.max(...response.data['temperature']);
        if (tempMax < 0) {
          tempMax = 24;
        }
        chartOptions.scales.yAxes[0].ticks.suggestedMax = tempMax + 5;
        chartOptions.scales.yAxes[0].ticks.stepSize = 5;

      } else if (indicatorFull === 'temperature:fahrenheit') {
        let tempMax: number = Math.max(...response.data['temperature']);
        if (tempMax < 0) {
          tempMax = 75;
        }
        chartOptions.scales.yAxes[0].ticks.suggestedMax = tempMax + 5;
      } else if (indicatorFull === 'humidity:percent') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 100;
        chartOptions.scales.yAxes[0].ticks.stepSize = 20;
        chartOptions.annotation.annotations.push({
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: 0,
          yMax: 40,
          backgroundColor: '#66666611',
          borderColor: '#00000000',
        });
        chartOptions.annotation.annotations.push({
          type: 'box',
          yScaleID: 'y-axis-0',
          yMin: 70,
          backgroundColor: '#66666611',
          borderColor: '#00000000',

        });
        gradientFill = '#00000000';

      } else if (indicatorUnit === 'indice') {
        chartOptions.scales.yAxes[0].ticks.suggestedMax = 10;
        chartOptions.scales.yAxes[0].ticks.stepSize = 2;
      }

      const threshold = response.info.thresholds[indicator];
      if (threshold !== undefined) {
        this.addChartLineAnnotation(
          chartOptions, 'Seuil de vigilance',
          threshold.warning, colorWarning,
        );
        this.addChartLineAnnotation(
          chartOptions, 'Seuil d\'alerte',
          threshold.critical, colorCritical,
        );
      }

      this.chartOptions = chartOptions;

      this.chartData = {
        labels: this.formatLabels(
          this.labelFormat, response.data.time),
        datasets: response.info.indicators.map((entry: any) => {
          return {
              label: [entry],
              data: response.data[entry],
              backgroundColor: gradientFill,
              pointRadius: 0,
              pointBorderWidth: 0,
              borderColor: '#87c7c8',
              borderWidth: 1.5,
          };
        }),
      };
    });
  }

  public mounted(): void {
    this.refresh();
  }

  private addChartLineAnnotation(
      chartOptions: any, title: string,
      value: number, color: string) {
    chartOptions.annotation.annotations.push({
      type: 'line',
      mode: 'horizontal',
      scaleID: 'y-axis-0',
      value,
      borderColor: color,
      borderWidth: 1,
      label: {
        enabled: false,
        content: title,
      },
    });
  }

  @Watch('areaId')
  @Watch('indicators')
  @Watch('dtFrom')
  @Watch('dtTo')
  @Watch('refreshKey')
  private onParamsChanged() {
    this.needRefresh = true;
    this.$nextTick().then(() => {
      if (this.needRefresh) {
        this.refresh();
        this.needRefresh = false;
      }
    });
  }

  private formatLabels(fmt: string, data: string[]): string[] {
    return data.map((entry) => {
      if (fmt === null) {
        return entry;
      }
      return date.formatDate(entry, fmt);
    });
  }

}
