import { Line, mixins } from 'vue-chartjs';
import 'chartjs-plugin-annotation';
import { watch } from 'fs';
const { reactiveProp } = mixins

export default {
  extends: Line,
  props: ['chartData', 'chartOptions'],
  async mounted() {
    this.renderChart(this.chartData, this.chartOptions);
  },

  watch: {
    chartData() {
      // XXX without this, chart is rendered without
      // options
      this.renderChart(this.chartData, this.chartOptions);
    },
  },
}